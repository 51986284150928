<template>
  <div class="home">
    <div class="banner">
      <div class="slogan">
        <div>
          助力临床医学研究
        </div>
        <div>
          打造开放式临床科研平台
        </div>
      </div>

      <div class="row introduce">
        <!-- <div class=" "> -->
        <div class="card col-md-3 col-sm-6">
          <img src="../assets/homeImages/1.png" class="card-img-top">
          <div class="card-body">
            <h5 class="card-title">专业的</h5>
            <p class="card-text">技术人才团队</p>
          </div>
        </div>
        <!-- </div> -->
        <!-- <div class=""> -->
        <div class="card col-md-3 col-sm-6">
          <img src="../assets/homeImages/2.png" class="card-img-top">
          <div class="card-body">
            <h5 class="card-title">科学的</h5>
            <p class="card-text">技术人才团队</p>
          </div>
        </div>
        <!-- </div> -->
        <!-- <div class=""> -->
        <div class="card col-md-3 col-sm-6">
          <img src="../assets/homeImages/3.png" class="card-img-top">
          <div class="card-body">
            <h5 class="card-title">严格的</h5>
            <p class="card-text">技术人才团队</p>
          </div>
        </div>
        <!-- </div> -->
        <!-- <div class=""> -->
        <div class="card col-md-3 col-sm-6">
          <img src="../assets/homeImages/4.png" class="card-img-top">
          <div class="card-body">
            <h5 class="card-title">完善的</h5>
            <p class="card-text">技术人才团队</p>
          </div>
        </div>
        <!-- </div> -->
      </div>
    </div>

    <!-- 中间 -->
    <div class="con">
      <!-- 公司简介 -->
      <div class="card-body text-center">
        <h5 class="card-title">公司简介</h5>
        <p style="color: #999999; font-size: 16px;">About us</p>
        <p class="card-text">
          西安鸿创医学科技有限公司是一家专注于真实世界研究及临床科研管理的新兴公司，致力于为临床科研人员提供专业的临床研究和科学学术服务，让用户更便捷地开展临床研究工作。<br>
          由鸿创团队自主研发的临床医学研究开放管理平台，为医疗机构、医药企业和政府机构提供项目管理、数据采集、质量控制、数据管理、数据分析、可视化与共享等功能，提高了数据采集效率，让您省时省力又省钱。
        </p>
        <a href="/about" class="btn btn-warning" style="color:white; font-size: 16px;">了解更多 >>></a>
      </div>
      <!-- 核心优势 -->
      <div class="core">
        <div class="core_title">
          <h5 class="core_text">核心优势</h5>
          <p style="color: #999999;">Core advantage</p>
        </div>
        <div class="container" style="max-width: 1200px; margin: 0 auto;">
          <!-- Set the max-width to your desired value -->
          <el-carousel :type="carouselType" :height="carouselHeight" :autoplay="true" interval="3000"
            indicator-position="outside">
            <el-carousel-item class="el-carousel-item">
              <div class="card">
                <div class="core-card-body">
                  <h5 class="core-card-title">研究方案设计指导</h5>
                  <p class="core-card-text">Research program design guidance</p>
                </div>
                <img src="../assets/homeImages/home_core.png" class="card-img-top" alt="Card Image">
                <div class="card-body">
                  <a href="/design" class="btn btn-primary">了解更多 >>></a>
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item class="el-carousel-item">
              <div class="card">
                <div class="core-card-body">
                  <h5 class="core-card-title">临床医学研究管理平台</h5>
                  <p class="core-card-text">Clinical medical research management platform</p>
                </div>
                <img src="../assets/homeImages/home_core2.png" class="card-img-top" alt="Card Image">
                <div class="card-body">
                  <a href="/managePlatform" class="btn btn-primary">了解更多 >>></a>
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item class="el-carousel-item">
              <div class="card">
                <div class="core-card-body">
                  <h5 class="core-card-title">医学统计分析</h5>
                  <p class="core-card-text">Medical statistical analysis</p>
                </div>
                <img src="../assets/homeImages/home_core.png" class="card-img-top" alt="Card Image">
                <div class="card-body">
                  <a href="/statistic" class="btn btn-primary">了解更多 >>></a>
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item class="el-carousel-item">
              <div class="card">
                <div class="core-card-body">
                  <h5 class="core-card-title">临床数据管理</h5>
                  <p class="core-card-text">Research program design guidance</p>
                </div>
                <img src="../assets/homeImages/home_core.png" class="card-img-top" alt="Card Image">
                <div class="card-body">
                  <a href="/data" class="btn btn-primary">了解更多 >>></a>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <!-- 专家团队 -->
      <!-- <div class="editing-team">
        <div class="team_title">
          <h5 class="team_text">专家团队</h5>
          <p style="color: #999999;">Editing team</p>
        </div> -->
      <!-- 团队照片轮播 -->
      <!-- <div id="team">
          <div class="row"> -->
      <!-- 左侧区域 -->
      <!-- <div class="col-md-4 col-sm-6 left-block">
              <div class="info">
                <h3 class="name">{{ selectedPerson.name }}</h3>
                <p class="education">学历或者职称:<b>{{ selectedPerson.education }}</b> </p>
                <p class="school">毕业学校: {{ selectedPerson.university }}</p>
                <p class="school">研究方向: {{ selectedPerson.research }}</p>
                <div class="info-bottom">
                  <p class="article">发表SCI文章: {{ selectedPerson.sciCount }}篇</p>
                  <p class="article">中文核心文章: {{ selectedPerson.coreCount }}篇</p>
                </div>

              </div>
            </div> -->

      <!-- 中间区域 -->
      <!-- <div class="col-md-4 col-sm-6 middle-block"> -->
      <!-- <h4>中间区域</h4> -->
      <!-- <img :src="selectedPerson.photo" class="img" alt="photo"> -->
      <!-- </div> -->

      <!-- 右侧区域 -->
      <!-- <div class="col-md-4 col-sm-12 right-block">
              <div v-for="(person, index) in people" :key="index" @click="selectPerson(index)">
                <img :src="people.phone" alt="photo">
              </div>
            </div> -->
      <!-- </div>
        </div>
      </div> -->
      <!-- 合作团队 -->
      <div class="cooperate-team ">
        <div class="cooperate_title">
          <h5 class="cooperate_text">合作团队</h5>
          <p style="color: #999999;font-size: 22px;">Cooperative unit</p>
        </div>
        <div class="cooperate-picture pc">
          <el-carousel indicator-position="outside" arrow="never" :autoplay="true" :interval="3000">
            <el-carousel-item>
              <div class="row" style="text-align: center;align-items: center;">
                <div class="col-sm-3 col-xs-12">
                  <img src="../assets/homeImages/1.jpg">
                  <div class="cooperate-text">陕西省中医医院</div>
                </div>
                <div class="col-sm-3 col-xs-12">
                  <img src="../assets/homeImages/2.jpg">
                  <div class="cooperate-text">西安交通大学第一附属医院</div>
                </div>
                <div class="col-sm-3 col-xs-12">
                  <img src="../assets/homeImages/3.jpg">
                  <div class="cooperate-text">唐都医院</div>
                </div>
                <div class="col-sm-3 col-xs-12">
                  <img src="../assets/homeImages/4.jpg">
                  <div class="cooperate-text">巴彦淖尔市医院</div>
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div class="row">
                <div class="col-sm-3">
                  <img src="../assets/homeImages/5.jpg">
                  <div class="cooperate-text">西安医学院第二附属医院</div>
                </div>
                <div class="col-sm-3">
                  <img src="../assets/homeImages/6.jpg">
                  <div class="cooperate-text">西安大兴医院</div>
                </div>
                <div class="col-sm-3">
                  <img src="../assets/homeImages/7.jpg">
                  <div class="cooperate-text">西安市第四医院</div>
                </div>
                <div class="col-sm-3">
                  <img src="../assets/homeImages/8.jpg">
                  <div class="cooperate-text">西安市中医医院</div>
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div class="row">
                <div class="col-sm-3">
                  <img src="../assets/homeImages/9.jpg">
                  <div class="cooperate-text">空军军医大学第三附属医院</div>
                </div>
                <div class="col-sm-3">
                  <img src="../assets/homeImages/cooperate10.jpg">
                  <div class="cooperate-text">宝鸡市人民医院</div>
                </div>


                <div class="col-sm-3">
                  <img src="../assets/homeImages/cooperate11.jpg">
                  <div class="cooperate-text">宝鸡市中心医院</div>
                </div>
                <div class="col-sm-3">
                  <img src="../assets/homeImages/cooperate12.jpg">
                  <div class="cooperate-text">西安红会医院</div>
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div class="row">
                <div class="col-sm-3">
                  <img src="../assets/homeImages/cooperate13.jpg">
                  <div class="cooperate-text">西安市第八医院</div>
                </div>
                <div class="col-sm-3">
                  <img src="../assets/homeImages/cooperate14.jpg">
                  <div class="cooperate-text">西安市第三医院</div>
                </div>
                <div class="col-sm-3">
                  <img src="../assets/homeImages/cooperate15.jpg">
                  <div class="cooperate-text">西安市第五医院</div>
                </div>
                <div class="col-sm-3">
                  <img src="../assets/homeImages/cooperate16.jpg">
                  <div class="cooperate-text">西安市儿童医院</div>
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div class="row">
                <div class="col-sm-3">
                  <img src="../assets/homeImages/cooperate17.jpg">
                  <div class="cooperate-text">西电集团医院</div>
                </div>
                <div class="col-sm-3">
                  <img src="../assets/homeImages/cooperate18.jpg">
                  <div class="cooperate-text">咸阳市第一人民医院</div>
                </div>
                <div class="col-sm-3">
                  <img src="../assets/homeImages/cooperate19.jpg">
                  <div class="cooperate-text">咸阳市中心医院</div>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="cooperate-picture mobile">
          <el-carousel indicator-position="outside" arrow="never" :autoplay="true" :interval="2000" :initial-index="0">
            <el-carousel-item>
              <div class="row">
                <div class="col-sm-3 col-xs-12">
                  <img src="../assets/homeImages/1.jpg">
                  <div class="cooperate-text">陕西省中医医院</div>
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div class="row">
                <div class="col-sm-3">
                  <img src="../assets/homeImages/2.jpg">
                  <div class="cooperate-text">西安交通大学第一附属医院</div>
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div class="row">
                <div class="col-sm-3">
                  <img src="../assets/homeImages/3.jpg">
                  <div class="cooperate-text">唐都医院</div>
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div class="row">
                <div class="col-sm-3">
                  <img src="../assets/homeImages/4.jpg">
                  <div class="cooperate-text">巴彦淖尔市医院</div>
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div class="row">
                <div class="col-sm-3">
                  <img src="../assets/homeImages/5.jpg">
                  <div class="cooperate-text">西安医学院第二附属医院</div>
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div class="row">
                <div class="col-sm-3">
                  <img src="../assets/homeImages/6.jpg">
                  <div class="cooperate-text">西安大兴医院</div>
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div class="row">
                <div class="col-sm-3">
                  <img src="../assets/homeImages/7.jpg">
                  <div class="cooperate-text">西安市第四医院</div>
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div class="row">
                <div class="col-sm-3">
                  <img src="../assets/homeImages/8.jpg">
                  <div class="cooperate-text">西安市中医医院</div>
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div class="row">
                <div class="col-sm-3">
                  <img src="../assets/homeImages/9.jpg">
                  <div class="cooperate-text">空军军医大学第三附属医院</div>
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div class="row">
                <div class="col-sm-3">
                  <img src="../assets/homeImages/cooperate10.jpg">
                  <div class="cooperate-text">宝鸡市人民医院</div>
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div class="row">
                <div class="col-sm-3">
                  <img src="../assets/homeImages/cooperate11.jpg">
                  <div class="cooperate-text">宝鸡市中心医院</div>
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div class="row">
                <div class="col-sm-3">
                  <img src="../assets/homeImages/cooperate12.jpg">
                  <div class="cooperate-text">西安红会医院</div>
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div class="row">
                <div class="col-sm-3">
                  <img src="../assets/homeImages/cooperate13.jpg">
                  <div class="cooperate-text">西安市第八医院</div>
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div class="row">
                <div class="col-sm-3">
                  <img src="../assets/homeImages/cooperate14.jpg">
                  <div class="cooperate-text">西安市第三医院</div>
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div class="row">
                <div class="col-sm-3">
                  <img src="../assets/homeImages/cooperate15.jpg">
                  <div class="cooperate-text">西安市第五医院</div>
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div class="row">
                <div class="col-sm-3">
                  <img src="../assets/homeImages/cooperate16.jpg">
                  <div class="cooperate-text">西安市儿童医院</div>
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div class="row">
                <div class="col-sm-3">
                  <img src="../assets/homeImages/cooperate17.jpg">
                  <div class="cooperate-text">西电集团医院</div>
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div class="row">
                <div class="col-sm-3">
                  <img src="../assets/homeImages/cooperate18.jpg">
                  <div class="cooperate-text">咸阳市第一人民医院</div>
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div class="row">
                <div class="col-sm-3">
                  <img src="../assets/homeImages/cooperate19.jpg">
                  <div class="cooperate-text">咸阳市中心医院</div>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <div class="news">
        <div class="news_title ">
          <h5 class="news_text">新闻动态</h5>
          <p style="color: #999999;font-size: 22px;">News</p>
        </div>
          <div class="news_details col-md-6 col-sm-12 ">
            <div class="news_img">
              <img src="../assets/homeImages/news_details.jpg" alt="">
            </div>
            <div class="news_title col-md-6 col-sm-12">
              <div class="news_con" v-for="(item, index) in News" :key="index">
                <p class="date">{{ item.date }}</p>
                <p class="title" @click="toPath(item)">{{ item.title }}</p>
              </div>
            </div>
          </div>
        <a href="/News" class="btn btn-warning">了解更多 >>></a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      carouselType: 'card',
      carouselHeight: '25rem',
      coreItems: [
        { title: '研究方案设计指导', description: 'Research program design guidance', image: '../assets/home_core.png', link: '/design' },
        { title: '临床医学研究管理平台', description: 'Clinical medical research management platform', image: '../assets/home_core2.png', link: '/design' },
        { title: '医学统计分析', description: 'Medical statistical analysis', image: '../assets/home_core3.png', link: '/design' },
        { title: '临床数据管理', description: 'Clinical data management', image: '../assets/home_core4.png', link: '/design' },
      ],
      carouselItems: [
        { id: 1, imgSrc: '../assets/cooperate1.png', text: "空军军医大学第三附属医院 " },
        { id: 2, imgSrc: '../assets/cooperate2.png', text: "第一附属医院 " },
        { id: 3, imgSrc: '../assets/cooperate3.png', text: "唐都医院 " },
        { id: 4, imgSrc: '../assets/cooperate4.png', text: "交大附属医院 " },
      ],
      selectedPerson: {},
      people: [
        { name: "张三", photo: "person1.jpg", education: "博士", university: "清华大学", research: "人工智能", sciCount: 2, coreCount: 3 },
        { name: "李四", photo: "person2.jpg", education: "硕士", university: "北京大学", research: "机器学习", sciCount: 1, coreCount: 4 },
        { name: "王六", photo: "person3.jpg", education: "本科", university: "上海交通大学", research: "数据分析", sciCount: 3, coreCount: 1 },
        { name: "王麻子", photo: "person3.jpg", education: "本科", university: "上海交通大学", research: "数据分析", sciCount: 3, coreCount: 1 },
        { name: "张三丰", photo: "../assets/cooperate4.png", education: "本科", university: "上海交通大学", research: "数据分析", sciCount: 3, coreCount: 1 }
      ],
      News: [{
        newsId: 1,
        date: '2022年03月18日',
        title: '国家卫生健康委办公厅关于印发临床营养科建设与管理指南（试行）的通知'
      },
      {
        newsId: 2,
        date: ' 2022年03月25日',
        title: '《临床营养科建设与管理指南(试行)》解读'
      },
      {
        newsId: 3,
        date: '2022年03月23日',
        title: '关于开展社区医养结合能力提升行动的通知'
      }],

    }
  },

  computed: {
    isPC() {
      return window.innerWidth >= 768;
    },
  },
  watch: {
    isPC(newValue) {
      this.carouselType = newValue ? 'card' : 'static';
      this.carouselHeight = newValue ? '25rem' : 'auto';
    },
  },
  mounted() {
    //展现个人信息
    if (this.people.length > 0) {
      this.selectedPerson = this.people[0]; //默认情况下设置第一个人
    }
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    selectPerson(index) {
      this.selectedPerson = this.people[index];
    },

    getCarouselItemStyle(index) {
      return index === this.hoveredIndex && this.isPC ? { transform: 'scale(1.2)' } : {};
    },
    handleResize() {
      this.$forceUpdate();
    },
    toPath(item) {
      switch (item.newsId) {
        case 1:
          this.$router.push({ path: '/firstNews' });
          break;
        case 2:
          this.$router.push({ path: '/secondNews' });
          break;
        case 3:
          this.$router.push({ path: '/thirdNews' });
          break;
        default:
          // Redirect to a default page if newsId doesn't match any case
          // this.$router.push({ path: '/defaultNewsDetail' });
          break;
      }
    }
  },
}


</script>
<style scoped lang="less">
.cooperate-picture {
  display: none;
}

.cooperate-text {
  font-size: 18px;
  // color: #0D80FF;
  padding-top: 40px;
  text-align: center;
  align-items: center;
}

@media (min-width: 769px) {
  .pc {
    display: block;

  }

  .mobile {
    display: none;

  }

  .pc .cooperate-picture {
    margin: auto;
    width: 1586px;
  }
}

@media (max-width: 768px) {
  .pc {
    display: none;
  }

  .mobile {
    display: block;
  }
}

/* 移动端样式 */
@media screen and (max-width: 767px) {


  .banner {
    width: 100%;
    height: 320px;
    background-image: url(../assets/homeImages/bc1_phone.jpg);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    position: relative;

    .banner_text {
      width: 385px;
      height: 200px;
      text-align: center;

      .line {
        position: relative;
        top: 2%;
        left: 44%;
        width: 30px;
        height: 2px;
        border-radius: 0;
        opacity: 1;
        border: 1px solid black;
        margin: 24px 0;
      }
    }

    .slogan {
      padding-bottom: 228px;
      font-size: 28px;
      color: #666666;
      align-items: center;
      text-align: center;
    }
  }

  .introduce {
    width: 90%;
    max-width: 1600px;
    height: 212px;
    background-image: url(../assets/homeImages/introduce-phone.jpg);
    // background-color: #ffffff;
    position: absolute;
    bottom: 0;
    left: 54%;
    transform: translateX(-50%);
    border-radius: 15px;
    font-size: 24px;
    color: #333333;

  }

  .introduce .card:first-child {
    background-color: #077dfb;
    color: white;
  }

  .introduce .card:nth-child(3) {
    background-color: #ffbc00;
    color: white;
  }

  .introduce .card-title {
    font-size: 22px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    // color: #0d80ff;
  }

  // .introduce .card:hover {
  //   transform: scale(1);
  //   background-color: #ffbc00;
  //   color: #0d80ff;
  //   border-radius: 15px;
  // }
  // .introduce .card:hover .card-text {
  //   color: white;
  // }
  .card {
    padding: 10px !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    border: none;
    transition: transform 0.3s, background-color 0.3s;
    cursor: pointer;
    border: #DCDCDC solid 2px;
  }

  .card-img-top {
    width: 82px;
    height: 82px;
    border: 1px dashed gray;
    margin-bottom: 10px;
  }

  // 公司简介.
  .con {
    .card-body {
      .card-text {
        font-size: 18px;
      }

      .card-title {
        font-size: 36px;
        color: #0D80FF;

        .card-in {
          font-size: 18px;
          color: #cdcbcb;
        }
      }
    }
  }


  .core {
    text-align: center;

    //核心优势
    .core_title {
      .core_text {
        font-size: 36px;
        color: #0D80FF;
        padding-top: 25px;
      }
    }

    .card {
      .card-img-top {
        width: 127px;
        height: 127px;
        border: 1px dashed grey;
        margin: auto;
      }

      .core-card-title {
        font-size: 22px;
        font-weight: bold;
      }




      .btn:hover {
        background-color: #FFBC00;
        color: white;
        border: 1px solid #FFBC00;
      }
    }

    .el-carousel-item {
      width: 252px;
      height: 410px;
    }

    /deep/ .el-carousel__indicators {
      left: unset;
      transform: unset;
      right: 2%;
    }

    /deep/ .el-carousel__button {
      background: #908e8e;
      opacity: 1;
    }

    /deep/ .is-active .el-carousel__button {
      background: #14A5FE;
      opacity: 1;
    }
  }

  #team {
    .left-block {
      width: 100%;
      /* Full width on mobile */

      .name {
        font-size: 30px;
        color: #0D80FF;
      }

      .education,
      .school {
        font-size: 16px;
      }

      .info-bottom {
        display: flex;
        width: 100%;
        /* Full width on mobile */
        justify-content: space-evenly;
        margin: auto;
      }

      .article {
        height: 50px;
        line-height: 50px;
        font-size: 14px;
        color: #0D80FF;
        border: #0D80FF 2px solid;
        border-radius: 4px;
        font-weight: bold;
      }
    }

    .middle-block {
      width: 100%;
      /* Full width on mobile */

      .img {
        width: 155px;
        height: 215px;
      }
    }

    .right-block {
      width: 100%;
      /* Full width on mobile */
      margin-top: 25px;

      img {
        width: 50px;
        height: 50px;
        cursor: pointer;
        margin-bottom: 5px;
        border: 1px dashed gray;
      }
    }
  }

  // .editing-team {
  //   text-align: center;

  //   .team_text {
  //     font-size: 36px;
  //     color: #0D80FF;
  //     padding-top: 83px;
  //   }
  // }

  .text-center {
    margin-top: 728px;
  }

  .cooperate-team {
    .cooperate_title {
      text-align: center;
    }

    .cooperate_text {
      font-size: 36px;
      color: #0D80FF;
      margin-top: 10px;
    }

    .pc,
    .col-sm-3 {
      text-align: center;
    }


  }

  .news {
    text-align: center;
  }

  .news_img img {
    max-width: 100%;
    height: auto;
  }

  .news_text {
    font-size: 24px;
    color: #0D80FF;
    padding-top: 20px;
  }

  .news_con {
    padding: 10px;
    border-bottom: #DCDCDC 1px solid;
  }

  .date {
    color: #7E7D7D;
    font-size: 14px;
  }

  .title {
    color: #333333;
    font-size: 16px;
  }

  .title:hover {
    cursor: pointer;
    color: #077dfb;
  }

  .btn {
    color: white;
    margin: 20px;
  }

}




@media screen and (min-width: 768px) {

  /* PC端样式 */
  .home {
    height: 3135px;

    .banner {
      width: 100%;
      height: 724px;
      background-image: url(../assets/homeImages/bc1.jpg);
      position: relative;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;

      .slogan {
        padding-top: 226px;
        font-size: 60px;
        color: #666666;
        align-items: center;
        text-align: center;
      }
    }

    /* 技术人才 */
    .introduce {
      width: 100%;
      max-width: 1600px;
      height: 212px;
      background-color: #ffffff;
      position: absolute;
      bottom: 0;
      left: 50.6%;
      transform: translateX(-50%);
      border-radius: 15px;
      font-size: 24px;
      color: #333333;
    }

    .introduce .card:first-child {
      background-color: #077dfb;
      color: white;
    }

    .introduce .card:nth-child(3) {
      background-color: #ffbc00;
      color: white;
    }

    .introduce .card-title {
      font-size: 22px;
      font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
      // color: #0d80ff;
    }

    // .introduce .card:hover {
    //   transform: scale(1);
    //   background-color: #ffbc00;
    //   color: #0d80ff;
    //   border-radius: 15px;
    // }
    // .introduce .card:hover .card-text {
    //   color: white;
    // }
    .card {
      padding: 10px !important;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      border: none;
      transition: transform 0.3s, background-color 0.3s;
      cursor: pointer;
    }

    .card-img-top {
      width: 82px;
      height: 82px;
      border: 1px dashed gray;
      margin-bottom: 10px;
    }
  }



  .con {
    width: 100%;
    height: 2411px;

    //公司简介
    .card-text {
      font-size: 18px;
    }

    .text-center {
      width: 100%;
      height: 512px;
      background-image: url(../assets/homeImages/bc3.jpg);
      padding-top: 90px;

      .card-title {
        font-size: 36px;
        color: #0D80FF;

        .card-text {
          font-size: 20px;
          color: #999999;

          .btn_color {
            color: #FFFFFF;
          }
        }
      }
    }

    .core {
      width: 100%;
      height: 560px;
      background-color: #DCE8FC;
      text-align: center;


      .el-carousel-item {
        width: 20rem;
        height: 25rem;
        margin-left: 130px;
      }

      /deep/ .el-carousel__indicators {
        left: unset;
        transform: unset;
        right: 2%;
      }

      /deep/ .el-carousel__button {
        background: #908e8e;
        opacity: 1;
      }

      /deep/ .is-active .el-carousel__button {
        background: #14A5FE;
        opacity: 1;
      }

      .card {
        align-items: center;
        border: 1px dashed gray;
        margin: auto;

        .card-img-top {
          width: 127px;
          height: 127px;
          border: 1px dashed gray;
        }

        .btn {
          width: 210px;
          height: 60px;
          font-size: 16px;
          line-height: 44px;
          align-items: center;
        }

        .btn:hover {
          background-color: #FFBC00;
          color: white;
          border: 1px solid #FFBC00;
        }
      }

      .core_text {
        font-size: 36px;
        color: #0D80FF;
        padding-top: 25px;
      }

      .core-card-body {
        margin-top: 45px;
      }

      .core-card-title {
        font-size: 20px;
        font-weight: bold;
        width: 290px;
      }

      .core-card-text {
        color: #666666;
        font-size: 16px;
      }

      .btn-style {
        width: 200px;
        height: 60px;
        font-size: 14px;
        color: black;
        background-color: #EEEEEE;
      }

      .btn-style:hover {
        background-color: #FFBC00;
      }
    }

    // .editing-team {
    //   height: 880px;
    //   background-image: url(../assets/homeImages/team.jpg);
    //   text-align: center;

    //   .team_text {
    //     font-size: 36px;
    //     color: #0D80FF;
    //     padding-top: 83px;
    //   }

    //   #team {
    //     .row {
    //       margin-top: 55px;
    //       display: flex;
    //       flex-wrap: wrap;
    //     }

    //     .container {
    //       margin-top: 50px;
    //     }

    //     .left-block {
    //       margin-top: 150px;
    //       margin: auto;

    //       .name {
    //         font-size: 30px;
    //         color: #0D80FF;
    //       }

    //       .education,
    //       .school {
    //         font-size: 16px;
    //         margin: 30px;
    //       }

    //       .info-bottom {
    //         display: flex;
    //         width: 400px;
    //         justify-content: space-evenly;
    //         margin: auto;
    //       }

    //       .article {
    //         width: 168px;
    //         height: 50px;
    //         line-height: 50px;
    //         font-size: 18px;
    //         color: #0D80FF;
    //         border: #0D80FF 2px solid;
    //         border-radius: 4px;
    //         font-weight: bold;
    //       }
    //     }

    //     .middle-block {
    //       margin-top: 15px;
    //       width: 50%;

    //       .img {
    //         width: 410px;
    //         height: 550px;
    //       }
    //     }

    //     .right-block {
    //       margin-top: 50px;

    //       img {
    //         width: 80px;
    //         height: 80px;
    //         cursor: pointer;
    //         margin-bottom: 5px;
    //         border: 1px dashed gray;
    //       }
    //     }
    //   }
    // }

    .cooperate-team {
      height: 426px;
      text-align: center;
      background-color: rgb(247, 246, 246);

      .cooperate-picture {
        margin: auto;
        width: 1586px;

      }

      .cooperate_text {
        font-size: 36px;
        color: #0D80FF;
        padding-top: 20px;
      }

      /deep/ .el-carousel__indicators {
        left: unset;
        transform: unset;
        right: 2%;
      }

      /deep/ .el-carousel__button {
        width: 10px;
        height: 10px;
        border: none;
        border-radius: 50%;
        background: #908e8e;
        opacity: 1;
      }

      /deep/ .is-active .el-carousel__button {
        background: #14A5FE;
        opacity: 1;
      }
    }

    .news {
      height: 900px;
      background-image: url(../assets/homeImages/News.jpg);
      text-align: center;

      .news_text {
        font-size: 36px;
        color: #0D80FF;
        padding-top: 100px;
      }

      .news_details {
        width: 80%;
        margin: auto;
        display: flex;
        justify-content: space-evenly;

        .news_img {
          height: 492px;
        }

        .news_title {
          width: 45%;
          text-align: left;
          height: 492px;

          .news_con {
            height: 116px;
            border-bottom: #DCDCDC 1px solid;

            .date {
              color: #7E7D7D;
              font-size: 16px;
            }

            .title {
              color: #333333;
              font-size: 20px;
            }

            .title:hover {
              cursor: pointer;
              color: #077dfb;
            }
          }
        }
      }

      .btn {
        color: white;
        margin-top: 80px;
      }
    }
  }


}</style>